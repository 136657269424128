import { Box, Container, Grid, GridItem, Heading, Image, Text, Button, Checkbox, Center, HStack, Spinner, SimpleGrid, ColorModeScript, ChakraProvider, ThemeConfig, extendTheme, useInterval, Accordion, AccordionItem, AccordionButton, AccordionPanel, createMultiStyleConfigHelpers, background } from "@chakra-ui/react"
import { checkboxAnatomy } from '@chakra-ui/anatomy'
import logo from '../logo.svg';
import { loremIpsum } from "lorem-ipsum";
import { useEffect, useState } from "react";
import download from '../download.svg'
import axios from "axios";
import { useConfig } from "../hooks/useConfig";
const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

enum ContractStep {
    FirstContract = 'Revenue Based Finance Agreement',
    SecondContract = 'Personal Guarantee',
    Download = 'Download'
}


const ContractPage = () => {

    const config = useConfig();
    const [step, setStep] = useState<ContractStep>(ContractStep.FirstContract);
    const [applicationId, setApplicationId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const downloadContract = async () => {
        const res = await axios.get(`https://liberisproductioncdn.blob.core.windows.net/files/Finance Agreement.pdf`, { responseType: 'blob' });
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = "TestContract.pdf"; // the filename you want
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        window.parent.postMessage(JSON.stringify({
            status: "download-requested",
            url: "https://liberisproductioncdn.blob.core.windows.net/files/Finance Agreement.pdf"
        }), '*')
    }

    useEffect(() => {
        setLoading(true)

        const location = window.location.toString().replace('#', '/');
        const applicationId = new URL(location).searchParams.get('applicationId');

        const getStatus = async (applicationId: string) => {
            if (config && applicationId) {
                try {
                    const res = await axios.get(`${config?.api.base}${config?.api.control.contract}?application_id=${applicationId}`);
                    if (res.data) {
                        setStep(res.data)
                    }
                    setLoading(false)
                }
                catch (e) {
                    console.log(e)
                }
            }
        }

        if (applicationId) {
            setApplicationId(applicationId);
            getStatus(applicationId);
        }

    }, [config]);


    const baseStyle = definePartsStyle({
        control: {
            background: 'white'
        },
    })

    const checkboxTheme = defineMultiStyleConfig({ baseStyle })

    const theme = extendTheme({
        config,
        styles: {
            global: (props: any) => ({
                body: {
                    bg: 'white',
                }
            })
        },
        components: { Checkbox: checkboxTheme },
    })


    return (
        <div>
            <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom-right' } }}>
                <Box bg='white' color='black'>
                    <Grid templateColumns='repeat(5, 1fr)' gap={0}>
                        <GridItem minWidth={['5vw', '10vw',]} />
                        <GridItem minWidth={['90vw', '80vw']} h='100%' >
                            {
                                step === ContractStep.FirstContract && <ContractView loading={loading} step={ContractStep.FirstContract} setStep={setStep} downloadContract={downloadContract} applicationId={applicationId} />
                            }
                            {
                                step === ContractStep.SecondContract && <ContractView loading={loading} step={ContractStep.SecondContract} setStep={setStep} downloadContract={downloadContract} applicationId={applicationId} />
                            }
                            {
                                step === ContractStep.Download && <DownloadView loading={loading} downloadContract={downloadContract} />
                            }

                        </GridItem >
                        <GridItem minWidth={['5vw', '10vw']} />
                    </Grid>
                    <Box w='100vw' p='5%' bg='#E9E9DE' marginTop={'40px'} minH={'300px'}>
                        <Container maxWidth={'container.lg'}>

                            <Text fontSize={'14px'}>This product is provided by Liberis Ltd, Scale Space Building, 1st Floor, 58 Wood Lane London, W12 7RZ (company number: 05654231. Liberis’ Revenue Based Finance is a form of receivables finance not a loan. Liberis is not authorised or regulated by the Financial Conduct Authority and the Financial Ombudsman Service will not be able to consider a complaint about Liberis. Amounts advanced are subject to status and our underwriting process before any offer can be made.</Text>

                            <SimpleGrid marginTop={'40px'} w={['100%', '60%']} columns={[2, 3]} spacing={2}>
                                <Text fontSize={'1rem'} textDecoration={'underline'} onClick={() => window.open(`https://www.liberis.com/privacy-policy/`, '_blank')}>Privacy Policy</Text>
                                <Text fontSize={'1rem'} textDecoration={'underline'} onClick={() => window.open(`https://www.liberis.com/terms-conditions/`, '_blank')}>Terms and Conditions</Text>
                                <Text fontSize={'1rem'} textDecoration={'underline'} onClick={() => window.open(`https://www.liberis.com/cookie-policy/`, '_blank')}>Cookie Notice</Text>
                            </SimpleGrid>
                        </Container>
                    </Box>
                </Box >
            </ChakraProvider>
        </div>
    )
}


const ContractView = (props: { step: ContractStep, setStep: any, downloadContract: () => any, loading: boolean, applicationId?: string }) => {
    const [checked, setChecked] = useState<boolean>();
    const [frameHeight, setFrameHeight] = useState<number>();
    const [lorem, setlorem] = useState<string>(loremIpsum({ count: 15, paragraphUpperBound: 10, suffix: "\n", units: "paragraphs" }));
    const config = useConfig()

    useEffect(() => {
        const heightLoop = setInterval(() => {
            const app = document.getElementById('root');

            const height = app?.scrollHeight;

            if (height && height > 0 && height !== frameHeight) {
                setFrameHeight(height)
                console.log('runngin')
                window.parent.postMessage(JSON.stringify({
                    containerHeight: height
                }), '*')
            }

        }, 100);

        return () => clearInterval(heightLoop);
    }, [frameHeight])

    const updateStatus = async (contractStep: ContractStep, application_id?: string) => {
        try {
            await axios.post(`${config.api.base}${config.api.control.contract}`, { application_id, contractStep });
        }
        catch (e) {
            console.log(e)
        }
    }

    const signContract = () => {
        setChecked(false);
        window.scrollTo({ top: 0, left: 0 })

        switch (props.step) {
            case ContractStep.FirstContract:
                props.setStep(ContractStep.SecondContract)
                updateStatus(ContractStep.SecondContract, props.applicationId)
                window.parent.postMessage(JSON.stringify({
                    trackingEventName: 'Button Click',
                    trackingLabel: 'sign-contract',
                }), '*')
                break;
            case ContractStep.SecondContract:
                updateStatus(ContractStep.Download, props.applicationId)
                window.parent.postMessage(JSON.stringify({
                    trackingEventName: 'Button Click',
                    trackingLabel: 'sign-contract',
                }), '*')
                window.parent.postMessage(JSON.stringify({
                    status: 'SigningComplete'
                }), '*')
                props.setStep(ContractStep.Download)

                break;
        }
        return;
    }

    return (
        <Box paddingTop={'50px'}>
            <Image src={logo}></Image>
            {props.loading && <Center>
                <Spinner marginTop='60%' size={'xl'} />
            </Center>}
            {!props.loading && <Box>
                <Heading fontSize={'1.625rem;'} fontWeight={'400'} size={'lg'} margin={'50px 0 20px 0'}>
                    {props.step}
                </Heading>
                <Text>Please read through the terms of your {props.step}. You will need to sign the {props.step} electronically by clicking the "Sign" button below.</Text>

                <Box marginTop={'20px'}>
                    <Text>{props.step}</Text>
                    <Accordion defaultIndex={[1]} allowMultiple>
                        <AccordionItem>
                            <h2>
                                <AccordionButton zIndex={9} padding={0}>
                                    <Box w='100%' padding={'10px'} h='50px' border={'1px solid #ddd'}>
                                        <Grid templateColumns='repeat(2, 1fr)' gap={0}>
                                            <GridItem textAlign={'left'} minWidth={['5vw', '20vw',]} >
                                                <Button left='0' size={'sm'} color={'gray'} variant={'link'}>Expand contract</Button>
                                            </GridItem>
                                            <GridItem textAlign={'right'} minWidth={['5vw', '20vw',]} >
                                                <Button zIndex={10} onClick={() => props.downloadContract()} size={'sm'} color={'#4F5659'} variant={'link'}>Download</Button>
                                            </GridItem>
                                        </Grid>
                                    </Box>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel border={'1px solid #ddd'} pb={1}>
                                <Box> <Image margin={'0 auto'} src={logo}></Image>
                                    <Heading size={'lg'} textAlign={'center'} margin='10px' color={'#4F5659'}>{props.step}</Heading>
                                    <Text>{lorem}</Text>
                                </Box>
                            </AccordionPanel>
                            <Box bg='#f6f6f6' p='15px' h='100%' border={'1px solid #ddd'}>
                                <AccordionButton padding={0}>
                                    <Checkbox onChange={() => { setChecked(!checked); window.scrollTo({ top: 0, left: 0 }) }}>I understand and agree to the terms of the {props.step}</Checkbox>
                                </AccordionButton>
                            </Box>
                        </AccordionItem>
                    </Accordion>





                    <Box margin={'80px 0 50px 0'}>
                        <Text as='b' marginBottom='20px'>please note:</Text>
                        <Text marginBottom='20px'>Liberis will perform more checks before signing your agreement. If the checks are successful we'll share a signed copy of the agreement with you, that's when the contract will become valid.</Text>
                        <Text marginBottom='20px'>By clicking the button below, I confirm that I Mr FirstName LastName am authorised to sign the {props.step} on behalf of Legal Name to legally bind it.</Text>
                    </Box>
                    <Box marginBottom={'5rem'}>
                        {checked && <Button minH={'3.125rem'} minW={'13.5rem'}  fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => signContract()}>SIGN</Button>}
                    </Box>
                </Box>
            </Box>}
        </Box>
    )
}

const DownloadView = (props: { downloadContract: () => any, loading: boolean }) => {
    const [frameHeight, setFrameHeight] = useState<number>();

    useEffect(() => {
        const heightLoop = setInterval(() => {

            const app = document.getElementById('root');

            const height = app?.scrollHeight;

            if (height && height > 0 && height !== frameHeight) {
                setFrameHeight(height)
                window.parent.postMessage(JSON.stringify({
                    containerHeight: height
                }), '*')
            }

        }, 100);

        return () => clearInterval(heightLoop);
    })

    const completeContract = () => {
        window.parent.postMessage(JSON.stringify({
            status: 'ContractComplete'
        }), '*')
        return;
    }

    return (
        <Box paddingTop={'50px'}>
            <Image src={logo}></Image>
            {props.loading && <Center>
                <Spinner marginTop='60%' size={'xl'} />
            </Center>}
            {
                !props.loading &&
                <Box>
                    <Heading fontSize={'1.625rem;'} size={'lg'} fontWeight={'400'} margin={'50px 0 20px 0'}>
                        Download your contracts
                    </Heading>
                    <Text>Thank you, we’ve got everything we need from you.</Text>

                    <Box padding={'20px'}>
                        <HStack spacing='24px'>
                            <Box >
                                <Image w='100px' src={download}></Image>
                            </Box>
                            <Box>
                                <Text fontWeight={'700'}>Finance Agreement</Text>
                                <Button fontWeight={'500'} fontSize={'16px'} color='#B3B3AA' onClick={() => props.downloadContract()} size={'sm'} variant={'link'}>Download</Button>
                            </Box>
                        </HStack>

                        <HStack spacing='24px'>
                            <Box >
                                <Image w='100px' src={download}></Image>
                            </Box>
                            <Box>
                                <Text fontWeight={'700'}>Personal Guarantee</Text>
                                <Button fontWeight={'500'} fontSize={'16px'} color='#B3B3AA' onClick={() => props.downloadContract()} size={'sm'} variant={'link'}>Download</Button>
                            </Box>
                        </HStack>
                        <Box marginTop={'5rem'}>
                            <Button minH={'3.125rem'} minW={'13.5rem'} bg={'#161031'} color={'rgb(134, 237, 131)'} onClick={() => completeContract()}>Continue</Button>
                        </Box>
                    </Box>
                </Box>}
        </Box >)
}
export default ContractPage
