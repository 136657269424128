import { Text, Box, Card, ChakraProvider, Container, Heading, Stack, useColorModeValue, Center, useToast, Divider, Tabs, Tab, TabList, TabPanel, TabPanels, Stepper, Spinner, Step, StepDescription, StepIndicator, StepSeparator, StepStatus, StepTitle, CardHeader, CardBody, Button, TableCaption, Tbody, Td, Tfoot, Th, Thead, Tr, Alert, Badge, AlertIcon, AlertTitle, AlertDescription, Tooltip, FormControl, FormLabel, Input, Select, Collapse, List, ListItem, UnorderedList, Textarea } from "@chakra-ui/react";
import axios from "axios";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AppContext, Decision, Merchant, MerchantRepayment, WebhookType } from "../App";
import { Advert, Adverts, EditAdvert, EditRedirect } from "./TailoredMarketingPage";
import { useDropzone } from 'react-dropzone';
import { ArrowForwardIcon, CheckCircleIcon, CheckIcon, CloseIcon, DownloadIcon, ExternalLinkIcon, NotAllowedIcon } from "@chakra-ui/icons";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import { useConfig } from "../hooks/useConfig";
const Joi = require('joi')
    .extend(require('@joi/date'));

const csv = require('csvtojson')

interface BusinessSnapshot {
    Company_ID: string,
    Entity_Type: string,
    Country_Code: string,
    Partner_Name: string,
    SubBrand: string,
    Customer_Start_Date: Date,
    Has_Email: number,
    Has_Merchant_Telephone: number
}

interface BusinessSnapshotErrors {
    snapshot: BusinessSnapshot,
    errors: any[]
}


interface TransactionSnapshotErrors {
    snapshot: TransactionSnapshot,
    errors: any[]
}

interface TransactionSnapshot {
    Company_ID: string,
    Merchant_ID: string,
    Partner_Name: string,
    SubBrand: string,
    MID_Start_Date: Date,
    Terminal_Type: string,
    MCC_Code: string,
    MCC_Description: string,
    Currency_Code: string,
    Country_Code: string,
    Transaction_Month: number,
    Transaction_Type: string,
    Turnover: number,
    Trans_Count: number
}

interface CombinedSnapshot {
    Company_ID: string,
    Country_Code: string,
    Currency_Code: string,
    SubBrand: string,
    TotalTransactions: number,
    TotalTurnover: number,
    MinOffer: number,
    MaxOffer: number,
}

const validateRow = (row: any, field: string) => {
    const err = row.errors?.filter((error: any) => {
        return error.path[1] === field;
    })

    console.log(err);
    if (err?.length > 0) {
        return <Box textAlign={'center'}><Tooltip hasArrow label={err[0].message.split('].')[1].replace("\"", "")} bg='gray.300' color='black'>
            <Badge colorScheme='red'>error</Badge></Tooltip ><Text>{row[field]}</Text></Box>
    }
    else {
        return row[field];
    }
}

const businessSnapColumns = [
    {
        name: 'Company_ID',
        selector: (row: any) => validateRow(row, 'Company_ID'),
        sortable: true,
    },
    {
        name: 'Entity_Type',
        selector: (row: any) => validateRow(row, 'Entity_Type'),
        sortable: true,
    }, {
        name: 'Country_Code',
        selector: (row: any) => validateRow(row, 'Country_Code'),
        sortable: true,
    }, {
        name: 'Partner_Name',
        selector: (row: any) => validateRow(row, 'Partner_Name'),
    }, {
        name: 'SubBrand',
        selector: (row: any) => validateRow(row, 'SubBrand'),
        sortable: true,
    }, {
        name: 'Customer_Start_Date',
        selector: (row: any) => validateRow(row, 'Customer_Start_Date'),
        sortable: true,
    }, {
        name: 'Has_Email',
        selector: (row: any) => validateRow(row, 'Has_Email'),
        sortable: true,
    }, {
        name: 'Has_Merchant_Telephone',
        selector: (row: any) => validateRow(row, 'Has_Merchant_Telephone'),
        sortable: true,
    },
];


const transactionSnapColumns = [
    {
        name: 'Company_ID',
        selector: (row: any) => validateRow(row, 'Company_ID'),
        sortable: true,
    },
    {
        name: 'Country_Code',
        selector: (row: any) => validateRow(row, 'Country_Code'),
        sortable: true,
    },
    {
        name: 'Currency_Code',
        selector: (row: any) => validateRow(row, 'Currency_Code'),
        sortable: true,
    },
    {
        name: 'MCC_Code',
        selector: (row: any) => validateRow(row, 'MCC_Code'),
    },
    {
        name: 'MCC_Description',
        selector: (row: any) => validateRow(row, 'MCC_Description'),
        sortable: true,
    },
    {
        name: 'MID_Start_Date',
        selector: (row: any) => validateRow(row, 'MID_Start_Date'),
        sortable: true,
    },
    {
        name: 'Merchant_ID',
        selector: (row: any) => validateRow(row, 'Merchant_ID'),
        sortable: true,
    },
    {
        name: 'Partner_Name',
        selector: (row: any) => validateRow(row, 'Partner_Name'),
        sortable: true,
    },
    {
        name: 'SubBrand',
        selector: (row: any) => validateRow(row, 'SubBrand'),
        sortable: true,
    },
    {
        name: 'Terminal_Type',
        selector: (row: any) => validateRow(row, 'Terminal_Type'),
        sortable: true,
    },
    {
        name: 'Trans_Count',
        selector: (row: any) => validateRow(row, 'Trans_Count'),
        sortable: true,
    },
    {
        name: 'Transaction_Type',
        selector: (row: any) => validateRow(row, 'Transaction_Type'),
        sortable: true,
    },
    {
        name: 'Transaction_Month',
        selector: (row: any) => validateRow(row, 'Transaction_Month'),
        sortable: true,
    },
    {
        name: 'Turnover',
        selector: (row: any) => validateRow(row, 'Turnover'),
        sortable: true,
    },
];

const combinedColumns = [
    {
        name: 'Company_ID',
        selector: (row: any) => row.Company_ID,
        sortable: true,
        grow: 1.5
    },
    {
        name: 'Country_Code',
        selector: (row: any) => row.Country_Code,
        sortable: true,
    },
    {
        name: 'Currency_Code',
        selector: (row: any) => row.Currency_Code,
        sortable: true,
    },
    {
        name: 'SubBrand',
        selector: (row: any) => row.SubBrand,
        sortable: true,
    },
    {
        name: 'Total Transactions',
        selector: (row: any) => row.TotalTransactions,
        sortable: true,
    },
    {
        name: 'Total Turnover',
        selector: (row: any) => row.TotalTurnover,
        sortable: true,
    },
    {
        name: 'Min Offer',
        selector: (row: any) => row.MinOffer,
        sortable: true,
    },
    {
        name: 'Max Offer',
        selector: (row: any) => row.MaxOffer,
        sortable: true,
    }
];

const steps = [
    { title: 'BUSINESS SNAPSHOT', description: <Text>Upload a business snapshot <br /> file to create a merchant base</Text>, onClick: (): any => { } },
    { title: 'TRANSACTION VOLUMES', description: <Text>Add a transaction snapshot file <br /> to build a view of their revenue</Text>, onClick: (): any => { } },
    { title: 'OFFERS GENERATED', description: <Text>Review their offers and <br /> create testable merchants</Text>, onClick: (): any => { } },
]

enum Stage {
    BusinessSnap,
    Transactions,
    Complete
}

const businessSnapshotSchema = Joi.array().items(Joi.object({
    Company_ID: Joi.string().required().max(100),
    Entity_Type: Joi.string().allow('').max(100),
    Country_Code: Joi.string().required().max(3),
    Partner_Name: Joi.string().required().max(150),
    SubBrand: Joi.string().required().max(150),
    Customer_Start_Date: Joi.date().format('YYYY-MM-DD').required(),
    Has_Email: Joi.string().valid("0", "1", ""),
    Has_Merchant_Telephone: Joi.string().valid("0", "1", "")
}));

const transactionSnapshotSchema = Joi.array().items(Joi.object({
    Company_ID: Joi.string().required().max(100),
    Merchant_ID: Joi.string().required().max(100),
    Partner_Name: Joi.string().required().max(100),
    SubBrand: Joi.string().required().max(150),
    MID_Start_Date: Joi.date().format('YYYY-MM-DD').required(),
    Terminal_Type: Joi.string().allow(null, ''),
    MCC_Code: Joi.string().allow(null, '').max(50),
    MCC_Description: Joi.string().allow(null, '').max(250),
    Currency_Code: Joi.string().required().max(3),
    Country_Code: Joi.string().required().max(3),
    Transaction_Month: Joi.date().format('YYYYMM').required(),
    Transaction_Type: Joi.string().valid("SALES", "CHARGEBACKS", "RETURNS").required().insensitive(),
    Turnover: Joi.number().required(),
    Trans_Count: Joi.number().required()
}));


const DataSharingPage: FC = () => {
    const [businessSnapshot, setBusinessSnapshot] = useState<BusinessSnapshot[]>([]);
    const [transactionSnapshot, setTransactionSnapshot] = useState<TransactionSnapshot[]>([]);
    const [stage, setStage] = useState<Stage>(Stage.BusinessSnap);
    const [loading, setLoading] = useState<boolean>();
    const [businessSnapshotErrors, setBusinessSnapshotErrors] = useState<BusinessSnapshotErrors[]>([]);
    const [transactionsSnapshotErrors, setTransactionsSnapshotErrors] = useState<TransactionSnapshotErrors[]>([]);

    let currentStep = 0;


    switch (stage) {
        case Stage.BusinessSnap:
            currentStep = 0
            break;
        case Stage.Transactions:
            currentStep = 1
            break;
        case Stage.Complete:
            currentStep = 2
            break;
    }


    const downloadErrorLog = (json: any, fileName: string) => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(json)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${fileName}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    return <ChakraProvider>
        <Container paddingTop='20px' maxW={'8xl'}>
            <Box h='100%' padding={'30px'}>
                <Stack spacing={10}>
                    <Box maxW={'1xl'}>
                        <Heading fontFamily={'LiberisDisplay'} size={'lg'}>Merchant data sharing</Heading>
                        <br></br>
                        <Text>
                            Monthly or daily merchant data sharing is an incredibly important step in partner integrations with Liberis when integrating and onboarding merchants. Sharing the data allows partners to do two things:
                        </Text>
                        <UnorderedList fontWeight={'light'}>
                            <ListItem>See which merchants are eligible for revenue based finance and allow Liberis to curate a selection file containing specific offers to be returned to the partner for marketing purposes.</ListItem>
                            <ListItem>Link transaction data to a merchant identifier when an offer is created via the 'Get offers' API. This allows us to quote merchants using the transaction data provided in these files.</ListItem>
                        </UnorderedList>
                        <br></br>

                        <Text> This tool allows you to test the formatting and data integrity of your partner sharing files. Liberis requires two files to build a financial overview of a merchant: </Text>
                        <br></br>
                        <Divider />
                        <br></br>
                        <Text>
                            Liberis requires two files sent via SFTP in order to build a financial overview of a merchant:
                        </Text>
                        <i>*SFTP credentials and whitelist are provided during partner integration and onboarding</i>
                        <br></br>
                        <br></br>

                        <UnorderedList>
                            <Text fontFamily={'FoundryPlek'}>Business Snapshot</Text>
                            <Text>The business snapshot gives Liberis a set of pseudonymised rows containing merchant identitifiers and entity information without exposing personally identifiable information. We attach the transactions from the following file to this data to build a financial image of the merchant entity.</Text>
                            <br></br>
                            <Text fontFamily={'FoundryPlek'}>Transaction Snapshot</Text>
                            <Text>The Transaction snapshot in contrast contains daily aggregated information about a merchants transactions and revenue. We use this data to asssess performance and check eligibility, while also apply pricing logic to give a set of offers back to the partner for marketing, or to power our own marketing.</Text>

                        </UnorderedList>
                        <br></br>
                        <Text>Both of these files must conform to the <Button variant={'link'} colorScheme="blue" onClick={() => window.open(`https://liberis-api-v2.readme.io/reference/sharing-merchant-data`, '_blank')}>Gold Standard <ExternalLinkIcon /></Button> file requirements.</Text>
                    </Box>
                    <Center marginTop={'40px'}>
                        <Card border={'1px solid #dfe3e6'} shadow={'xl'} w={'80%'}>
                            <Stepper padding={'20px'} colorScheme='green' index={currentStep}>
                                {steps.map((step, index) => (
                                    <Step onClick={() => { step.onClick() }} key={index} style={{ cursor: 'pointer' }} >
                                        <StepIndicator>
                                            <StepStatus complete={<CheckIcon />} incomplete={``} active={<Spinner boxSize={'10px'} />} />
                                        </StepIndicator>

                                        <Box flexShrink='0' >
                                            <StepTitle><Text fontFamily={'FoundryPlek'}>{step.title}</Text></StepTitle>
                                            <StepDescription>{step.description}</StepDescription>
                                        </Box>

                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>
                        </Card>
                    </Center>
                    {
                        stage === Stage.BusinessSnap &&
                        <Stack spacing={20}>
                            {businessSnapshot.length > 0 &&
                                <Center>
                                    <Button mr={3} onClick={() => { setStage(Stage.Transactions) }} colorScheme="green" >Progress to transactions snapshot<ArrowForwardIcon /> </Button>
                                </Center>
                            }
                            {businessSnapshot.length === 0 && <Center>
                                <FileUploader title="Upload business snapshots" setData={setBusinessSnapshot} allowedSchema={businessSnapshotSchema} setErrors={setBusinessSnapshotErrors} />
                            </Center>}

                            {businessSnapshot.length > 0 &&
                                <Card border={'1px solid #dfe3e6'} shadow={'xl'}>
                                    <Table data={businessSnapshot} columns={businessSnapColumns} />
                                </Card>
                            }

                            {businessSnapshotErrors.length > 0 &&
                                <Stack>
                                    <Alert status='error'>
                                        <AlertIcon />
                                        <Stack>
                                            <AlertTitle>Errors detected in file</AlertTitle>
                                            <AlertDescription fontWeight={'light'}>Please correct the file and try again. You can hover over the errors to see why the row failed validation or <Button colorScheme="blue" variant={'link'} onClick={() => downloadErrorLog(businessSnapshotErrors, 'businesssnapshot-validation-failures.json')}>download a validation log.</Button></AlertDescription>
                                        </Stack>
                                    </Alert>
                                    <Card border={'1px solid #dfe3e6'} shadow={'xl'}>

                                        <Table data={businessSnapshotErrors} columns={businessSnapColumns} />
                                    </Card>
                                </Stack>
                            }

                        </Stack>
                    }

                    {
                        stage === Stage.Transactions &&
                        <Stack spacing={20}>
                            {transactionSnapshot.length > 0 &&
                                <Center>
                                    <Button mr={3} onClick={() => { setLoading(true); setStage(Stage.Complete) }} isLoading={loading} spinnerPlacement='end' colorScheme="green" >Combine data<ArrowForwardIcon /> </Button>
                                </Center>
                            }
                            {transactionSnapshot.length === 0 && <Center>
                                <FileUploader title="Upload business snapshots" setData={setTransactionSnapshot} allowedSchema={transactionSnapshotSchema} setErrors={setTransactionsSnapshotErrors} />
                            </Center>
                            }
                            {transactionSnapshot.length > 0 &&
                                <Card border={'1px solid #dfe3e6'} shadow={'xl'}>
                                    <Table data={transactionSnapshot} columns={transactionSnapColumns} />
                                </Card>
                            }

                            {transactionsSnapshotErrors.length > 0 &&
                                <Stack>
                                    <Alert status='error'>
                                        <AlertIcon />
                                        <Stack>
                                            <AlertTitle>Errors detected in file</AlertTitle>
                                            <AlertDescription fontWeight={'light'}>Please correct the file and try again. You can hover over the errors to see why the row failed validation or <Button colorScheme="blue" variant={'link'} onClick={() => downloadErrorLog(transactionsSnapshotErrors, 'transactionsnapshot-validation-failures.json')}>download a validation log.</Button></AlertDescription>
                                        </Stack>
                                    </Alert>
                                    <Card border={'1px solid #dfe3e6'} shadow={'xl'}>

                                        <Table data={transactionsSnapshotErrors} columns={transactionSnapColumns} />
                                    </Card>
                                </Stack>
                            }
                        </Stack>
                    }

                    {
                        stage === Stage.Complete && <CompletePage transactionSnapshot={transactionSnapshot} businessSnapshot={businessSnapshot} setStage={setStage} setTransactionSnapshot={setTransactionSnapshot} />
                    }

                </Stack>
            </Box>
        </Container>
    </ChakraProvider >
}

const Table = (props: { data: any[], columns: any, selectableRows?: boolean, onSelect?: any }) => {

    return (
        <DataTable
            columns={props.columns}
            data={props.data}
            pagination
            selectableRows={props.selectableRows}
            onSelectedRowsChange={(rows: any) => { props.onSelect(rows.selectedRows); console.log(rows) }}
        />
    )
}

const CompletePage = (props: { transactionSnapshot: TransactionSnapshot[], businessSnapshot: BusinessSnapshot[], setStage: any, setTransactionSnapshot: any }) => {

    const [combinedSnapshot, setCombinedSnapshot] = useState<CombinedSnapshot[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [selectedMerchants, setSelectedMerchants] = useState<CombinedSnapshot[]>([]);
    const [decision, setDecision] = useState<string>();
    const [repaymentMech, setRepaymentMech] = useState<string>('Partner Split');
    const [webhookUrl, setUrl] = useState<string>();
    const [webhookType, setWebhookType] = useState<string>('No Auth');
    const [webhookSecret, setWebhookSecret] = useState<string>();
    const [webhookTokenPayload, setWebhookTokenPayload] = useState<string>();
    const [webhookTokenEndpoint, setWebhookTokenEndpoint] = useState<string>();


    const context = useContext(AppContext);
    const config = useConfig()

    useEffect(() => {
        const combineTables = () => {
            setLoading(true);

            setTimeout(() => {
                const combinedRows: CombinedSnapshot[] = props.businessSnapshot.map((business: BusinessSnapshot): CombinedSnapshot => {
                    const businessTransactions = props.transactionSnapshot.filter((transaction: TransactionSnapshot) => business.Company_ID === transaction.Company_ID)
                    const totalRevenue = businessTransactions.reduce(
                        (accumulator, currentValue) => accumulator + Number(currentValue.Turnover),
                        0,
                    );

                    const currencyCode = businessTransactions?.length > 0 ? businessTransactions[0].Currency_Code : '';

                    return {
                        Company_ID: business.Company_ID,
                        Country_Code: business.Country_Code,
                        Currency_Code: currencyCode,
                        SubBrand: business.SubBrand,
                        TotalTransactions: businessTransactions.length,
                        TotalTurnover: Number(totalRevenue.toFixed(0)),
                        MinOffer: 2500,
                        MaxOffer: Number((totalRevenue * 1.8).toFixed(0)),
                    }
                })
                setCombinedSnapshot(combinedRows.filter(x => x.MaxOffer > 2500));

            }, 1000)

            setLoading(false);
        }

        combineTables();
    }, [])

    const navigate = useNavigate();
    const toast = useToast();

    const createMerchants = async () => {
        if (selectedMerchants && selectedMerchants.length > 0) {
            try {
                const merchants = selectedMerchants.map((merch: CombinedSnapshot) => {
                    return {
                        merchantId: merch.Company_ID,
                        currency: merch.Currency_Code,
                        limits: {
                            minimum: merch.MinOffer,
                            maximum: merch.MaxOffer
                        },
                        webhookUrl,
                        decision: Decision[decision as keyof typeof Decision],
                        paymentMechanism: repaymentMech,
                        webhookType,
                        webhookTokenPayload,
                        webhookTokenEndpoint,
                        webhookSecret
                    }
                });

                await axios.post(`${config.api.base}${config.api.control.partnerData}`, { partner: context.userId, merchants })

                toast({
                    title: 'Merchants successfully created',
                    description: <Button variant={'link'} color='blue.200' onClick={() => navigate('/merchants')}>Click here to view</Button >,
                    status: 'success',
                    duration: 10000,
                    isClosable: false
                })
            } catch (e) {
                console.log(e)
                toast({
                    title: 'Merchants creation failed',
                    status: 'error',
                    duration: 10000,
                    isClosable: false
                })
            }
        }
        return;
    }


    return (<Stack spacing={20}>

        <Center>
            <Collapse in={selectedMerchants.length > 0} animateOpacity>
                <Card p='2' shadow={'lg'} border={'1px solid #dfe3e6'}>
                    <CardHeader>
                        <Heading size='md'>Create merchants</Heading>
                    </CardHeader>
                    <CardBody>
                        <Stack spacing={4}>
                            <Box>
                                <Stack>
                                    <FormControl mt={4} isRequired>
                                        <FormLabel>Decision result</FormLabel>
                                        <Select onChange={(e) => setDecision(e.target.value)} placeholder='Select decision'>
                                            <option>{Decision.Approved}</option>
                                            <option>{Decision.Declined}</option>
                                            <option>{Decision.Referred}</option>
                                        </Select>
                                    </FormControl>

                                    <FormControl mt={4} isRequired>
                                        <FormLabel>Payment Mechanism</FormLabel>
                                        <Select value={repaymentMech} onChange={(e) => setRepaymentMech(e.target.value)} placeholder='Select payment mechanism'>
                                            <option>{MerchantRepayment.PartnerSplit}</option>
                                            <option>{MerchantRepayment.LiberisSplit}</option>
                                        </Select>
                                    </FormControl>

                                    <FormControl mt={4}>
                                        <FormLabel>Webhook<Button position={'absolute'} right={'0'} top={0} variant='link' color='grey' w='150px' fontSize={'12px'} onClick={() => window.open(`${'https://public.requestbin.com/r'}`, '_blank')}>Create a request bin <ExternalLinkIcon /></Button></FormLabel>
                                        <Select value={webhookType} onChange={(e) => setWebhookType(e.target.value)} placeholder='Select auth type'>
                                            <option>{WebhookType.NoAuth}</option>
                                            <option>{WebhookType.Basic}</option>
                                            <option>{WebhookType.Oauth2}</option>
                                        </Select>
                                        <FormControl mt={4}>
                                            <FormLabel>Webhook Url</FormLabel>
                                        </FormControl>
                                        <Input onChange={(e) => setUrl(e.target.value)} placeholder='Url' />
                                        {webhookType === WebhookType.Oauth2 &&
                                            <Box>
                                                <FormControl mt={4} >
                                                    <FormLabel>Token endpoint</FormLabel>
                                                    <Input onChange={(e) => setWebhookTokenEndpoint(e.target.value)} placeholder='Url' />
                                                </FormControl>
                                                <FormControl mt={4} >
                                                    <FormLabel>Token request payload</FormLabel>
                                                    <Textarea onChange={(e: any) => setWebhookTokenPayload(e.target.value)} placeholder='Enter payload' />
                                                </FormControl>
                                            </Box>
                                        }
                                        {webhookType === WebhookType.Basic &&
                                            <Box>
                                                <FormControl mt={4} >
                                                    <FormLabel>API Key</FormLabel>
                                                    <Input onChange={(e) => setWebhookSecret(e.target.value)} placeholder='Secret' />
                                                </FormControl>
                                            </Box>
                                        }
                                    </FormControl>
                                </Stack>
                            </Box>
                            <Center>
                                <Button isLoading={selectedMerchants.length > 10 || !decision || !repaymentMech} spinner={<NotAllowedIcon />} mr={3} onClick={() => { createMerchants() }} colorScheme="green" >Create {selectedMerchants.length} merchants<ArrowForwardIcon /> </Button>
                            </Center>
                            {selectedMerchants.length > 10 &&
                                <Text>You can only create 10 merchants at a time</Text>
                            }
                        </Stack>
                    </CardBody>
                </Card>
            </Collapse>
        </Center>




        {combinedSnapshot.length === 0 &&
            <Stack>
                <Center>
                    <Spinner color="#E7FF7C" />
                </Center>
                <Center>
                    <Text>Combining and assessing <Text textAlign={'center'} color="#E7FF7C">eligibility...</Text></Text>
                </Center>
            </Stack>
        }

        {combinedSnapshot.length > 0 && <Stack spacing={5}>
            <Heading size={'sm'}>Select merchants to create for sandbox testing</Heading>
            <Card border={'1px solid #dfe3e6'} shadow={'xl'}>
                <Table data={combinedSnapshot} columns={combinedColumns} selectableRows onSelect={setSelectedMerchants} />
            </Card>
        </Stack>
        }
    </Stack>)
}


const FileUploader = (props: { title: string, setData: any, allowedSchema?: any, setErrors: any }) => {

    const [parsedData, setParsedData] = useState<any>([]);
    const [validating, setValidating] = useState<boolean>();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const csvText = reader.result;
                props.setErrors([]);
                setParsedData([]);
                setValidating(true);
                setTimeout(() => {
                    csv().fromString(csvText).then((e: any) => {
                        if (props.allowedSchema) {
                            const validationResult = props.allowedSchema.validate(e, { abortEarly: false });
                            if (validationResult.error) {
                                console.log(props.allowedSchema.validate(e, { abortEarly: false }));
                                const parsedWithErrors = validationResult.value.map((value: any, index: number) => {
                                    const rowErrors = validationResult.error.details.filter((error: any) => error.path[0] === index);
                                    if (rowErrors) {
                                        return {
                                            ...value, errors: rowErrors
                                        }
                                    }
                                })
                                props.setErrors(parsedWithErrors.filter((business: any) => business.errors.length > 0))
                            }
                            else {
                                setParsedData(e)
                            }
                            setValidating(false);
                        }
                        else {
                            setParsedData(e)
                        }
                    })
                }, 200);
            }
            reader.readAsText(file)
        })

    }, [])

    const fileSizeValidator = (file: File) => {
        console.log(file.size)
        if (file.size > 10000000) {
            return {
                code: "file-too-large",
                message: `File is larger than 10mb`
            };
        }
        return null
    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        accept: {
            'text/csv': [],
        },
        maxFiles: 1,
        validator: fileSizeValidator,
        noDragEventsBubbling: true,
        onDrop
    });

    const toast = useToast();

    const toastId = 'toast1';


    fileRejections.forEach(x => {
        if (!isDragActive && !toast.isActive(toastId)) {
            toast({
                title: `File ingestion for ${x.file.name} failed: `,
                description: x.errors[0].message,
                status: 'error',
                id: toastId,
                duration: 9000,
                isClosable: true
            })
        }
    })

    return (
        <Card border={'1px solid #dfe3e6'} shadow={'xl'} w='30rem'>
            <CardHeader>
                <Heading fontFamily={'FoundryPlek'} size={'sm'}>{props.title.toUpperCase()}</Heading>
            </CardHeader>
            <CardBody>
                <Stack spacing={5}>
                    <Center>
                        <Card w={'90%'} cursor={'pointer'} textAlign={'center'} p='10' border={'2px dashed #E2E8F0'} boxShadow={'none'} {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <Stack spacing={4}>
                                <Center><DownloadIcon boxSize={'25px'} /></Center>
                                <Text>Drag and drop or <Text>Choose file to upload</Text></Text>
                                <Text fontSize={'12px'}>CSV only</Text>
                            </Stack>
                        </Card>

                    </Center>
                    {validating &&
                        <Center>
                            <Stack>
                                <Center><Spinner color="#E7FF7C" /></Center>
                                <Text>Validating file...</Text>
                            </Stack>
                        </Center>
                    }
                    {acceptedFiles.length > 0 && !validating &&
                        <Stack>
                            <Text>File to be ingested: {acceptedFiles.map(x => <Text> {parsedData.length > 0 ? <CheckCircleIcon boxSize={'16px'} color={'green.500'} m={'10px'} /> : <NotAllowedIcon color={'red.500'} m={'10px'} />}{x.name}</Text>)}</Text>
                            {parsedData.length > 0 &&
                                <Box textAlign={'right'}>
                                    <Button mr={3} onClick={() => { props.setData(parsedData); console.log(parsedData) }} colorScheme="green" >Upload</Button>
                                </Box>
                            }
                        </Stack>
                    }
                </Stack>
            </CardBody>
        </Card>
    )
}
export default DataSharingPage