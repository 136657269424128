import { ArrowDownIcon, CheckIcon, CloseIcon, ChevronDownIcon, ArrowForwardIcon, SettingsIcon, RepeatIcon, ArrowUpIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Tooltip, Text, useToast, Image, Container, Heading, TableContainer, Thead, Tr, Th, Tbody, Td, useColorModeValue, Stepper, Step, StepIndicator, StepStatus, StepTitle, StepDescription, StepSeparator, Stack, MenuButton, MenuList, MenuItem, Box, Button, Card, Divider, Menu, Skeleton, Table, AbsoluteCenter, Tag, TagLabel, TagLeftIcon, Checkbox, useColorMode, Link, Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverFooter, PopoverHeader } from "@chakra-ui/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatCurrency, CopyButton, PaymentSetupModal, AcceptOfferModal, GetOffersModal, Merchant, Application, Deal, Page, Webhook, ApplicationDataWebhook, ApplicationStatusWebhook, ContractModal, CreatePaymentModal, DealRepaidModal, DealStatusModal, TestWebhookModal, ViewPaymentsModal, ViewWebhook, ViewDealModal, CardContainer, DeleteMerchantModal, ClaimsData, ViewPrequalifiedDataModal, ProductType } from "../App";
import cross from '../cross.svg'
import { useConfig } from "../hooks/useConfig";

const MerchantComponent = (props: { setPage: (page: Page) => void, scopedMerchant?: Merchant, userId?: string, connectionLost: any }) => {
    const config = useConfig();
    const [loading, setLoading] = useState<boolean>(true);
    const [applicationDataModal, setApplicationDataModal] = useState<boolean>(false);
    const [getOffersModalOpen, setGetOffersModalOpen] = useState<boolean>(false);
    const [time, setTime] = useState(0);
    const [initialised, setInitialised] = useState<boolean>();

    const [merchant, setMerchant] = useState<Merchant>();
    const [applications, setApplications] = useState<Application[]>();
    const [deals, setDeals] = useState<Deal[]>();
    const [revenue, setRevenue] = useState<ClaimsData>();

    const [selectedDeal, setSelectedDeal] = useState<Deal>();
    const [latestDeal, setLatestDeal] = useState<Deal>();
    const [selectedApplication, setSelectedApplication] = useState<Application>();

    const [webhooks, setWebhooks] = useState<Webhook[]>();
    const [dataModalOpen, setDataModal] = useState<boolean>(false);
    const [applicationStatusModalOpen, setApplicationStatusModal] = useState<boolean>(false);

    const [dealStatusModalOpen, setDealStatusModal] = useState<boolean>(false);
    const [dealDataModalOpen, setDealDataModal] = useState<boolean>(false);
    const [paymentModalOpen, setPaymentModal] = useState<boolean>(false);
    const [paymentSetupModalOpen, setPaymentSetupModal] = useState<boolean>(false);
    const [viewPaymentModalOpen, setViewPaymentModal] = useState<boolean>(false);
    const [dealRepaidModalOpen, setDealRepaidModal] = useState<boolean>(false);

    const [viewPrequalModalOpen, setViewPrequalModal] = useState<boolean>(false);
    const [selectedChannel, setSelectedChannel] = useState<string>();

    const [acceptModalOpen, setAcceptModal] = useState<boolean>(false);

    const [webhookModal, setWebhookModal] = useState<boolean>(false);
    const [testWebhookModal, setTestWebhookModal] = useState<boolean>(false);
    const [selectedWebhook, setSelectedWebhook] = useState<Webhook>()

    const [redirectUrl, setRedirectUrl] = useState<string>();

    const [contractModal, setContractModal] = useState<boolean>(false);

    const [deleteMerchantModal, setDeleteMerchantModal] = useState<boolean>(false);

    const [renewalFlow, setRenewalFlow] = useState<boolean>();

    const location = window.location.toString().replace('#', '/');
    const merchantId = new URL(location).searchParams.get('merchantId');

    const [resetsEnabled, setResetsEnabled] = useState<boolean>(false);
    const [resetPoint, setResetPoint] = useState<MerchantStage>()

    enum MerchantStage {
        Created = 1,
        OffersCreated = 2,
        OffersAccepted = 3,
        ContractSigned = 4,
        PaymentSetup = 5,
        PaymentsCreated = 6,
        DealRepaid = 7
    }

    const toggleResetEnabled = (enabled: boolean) => {
        if (enabled) {
            updateResetStage(MerchantStage.OffersCreated);
        }
        if (!enabled) {
            updateResetStage(undefined);
            setResetPoint(undefined);
        }
        setResetsEnabled(enabled);
    }

    useEffect(() => {
        console.log(resetsEnabled)
        let merchantLoop: any;

        const getMerchant = async () => {
            try {
                const response = await axios.get(`${config?.api.base}${config?.api.control.merchant}?merchantId=${merchantId}&partner=${props.userId}`)

                if (!resetPoint) {
                    setResetPoint(response.data.merchant.resetStage)
                }

                if (!resetsEnabled) {
                    setResetsEnabled(!!response.data.merchant.resetStage)
                }

                const mappedApplications = response.data.applications.map((x: any) => {
                    return {
                        merchantId: x.application.merchant_id,
                        status: x.status,
                        data: x
                    }
                })


                setApplications(mappedApplications)
                setMerchant(response.data.merchant)
                setDeals(response.data.deals)
                setWebhooks(response.data.webhooks)
                setRevenue(response.data.revenue);
                setRedirectUrl(response.data.redirect);


                const sortedApplications = mappedApplications.sort((a: any, b: any) => {
                    return new Date(a.data.createdAt).getTime() - new Date(b.data.createdAt).getTime();
                });

                const sortedDeals = response.data.deals.sort((a: any, b: any) => {
                    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                });

                if (selectedApplication) {
                    setSelectedApplication(sortedApplications.find((x: Application) => x.data.application_id === selectedApplication?.data.application_id))
                }
                else {
                    setSelectedApplication(sortedApplications[sortedApplications?.length - 1])
                }

                setLatestDeal(sortedDeals[sortedDeals?.length - 1]);

                if (selectedDeal) {
                    setSelectedDeal(sortedDeals.find((x: Deal) => x.id === selectedDeal?.id))
                }
                else {
                    setSelectedDeal(sortedDeals[sortedDeals?.length - 1])
                }
                setInitialised(true);
            }
            catch (e) {
                console.log(e);
                props.connectionLost()
            }
        }

        merchantLoop = setTimeout(() => {
            setTime(time + 1);
            getMerchant();
            setLoading(false);
        }, 2000);

        return () => {
            clearTimeout(merchantLoop);
        }
    }, [time, setLoading, selectedApplication, setSelectedApplication, selectedDeal, setSelectedDeal, merchantId, props])


    const deleteApplication = async (merchantId?: string) => {
        await axios.delete(`${config.api.base}${config.api.control.application}?merchantId=${merchantId}`)
    }

    const deleteDeal = async (dealId?: string) => {
        await axios.delete(`${config.api.base}${config.api.control.deal}?id=${dealId}`)

        toast({
            title: `Deleting deal ${dealId}`,
            description: "Deleting a deal also reverts the merchant and related application",
            status: 'warning',
            duration: 4000,
            isClosable: true
        })
    }

    const updateResetStage = async (stage?: MerchantStage) => {
        await axios.patch(`${config.api.base}${config.api.control.resetStage}`, { stage, merchantId })
    }

    const resetMerchant = async (stage?: MerchantStage) => {
        await axios.post(`${config.api.base}${config.api.control.refresh}`, { merchantId })
    }

    const toast = useToast();

    const steps = [
        { stage: MerchantStage.Created, title: 'Merchant Created', description: '', onClick: (): any => { } },
        { stage: MerchantStage.OffersCreated, title: 'Offers Created', description: '', onClick: (): any => { setGetOffersModalOpen(true); setRenewalFlow(false) } },
        { stage: MerchantStage.OffersAccepted, title: 'Offer Accepted', description: '', onClick: (): any => { setAcceptModal(true) }, block: true },
        { stage: MerchantStage.ContractSigned, title: 'Contract Signed', description: '', onClick: (): any => { setContractModal(true) } },
        { stage: MerchantStage.PaymentSetup, title: 'Payment Setup', description: '', onClick: (): any => { setPaymentSetupModal(true) } },
        { stage: MerchantStage.PaymentsCreated, title: 'Payments Created', description: '', onClick: (): any => { setPaymentModal(true) } },
        { stage: MerchantStage.DealRepaid, title: 'Deal Repaid / Closed', description: '', onClick: (): any => { setDealRepaidModal(true) } }
    ]

    const flexiSteps = [
        { stage: MerchantStage.Created, title: 'Merchant Created', description: '', onClick: (): any => { } },
        { stage: MerchantStage.OffersCreated, title: 'Application Created', description: '', onClick: (): any => { setGetOffersModalOpen(true); setRenewalFlow(false) } },
        { stage: MerchantStage.ContractSigned, title: 'Contract Signed', description: '', onClick: (): any => { setContractModal(true) } },
        { stage: MerchantStage.PaymentSetup, title: 'Payment Setup', description: '', onClick: (): any => { setPaymentSetupModal(true) } },
        { stage: MerchantStage.OffersAccepted, title: 'Advance Created', description: '', onClick: (): any => { setAcceptModal(true) }, block: true },
        { stage: MerchantStage.PaymentsCreated, title: 'Payments Created', description: '', onClick: (): any => { setPaymentModal(true) } },
        { stage: MerchantStage.DealRepaid, title: 'Account Repaid / Closed', description: '', onClick: (): any => { setDealRepaidModal(true) } }
    ]

    const renewalSteps = [
        { title: 'Merchant Created', description: '' },
        { title: 'Offers Created', description: '', onClick: () => { setGetOffersModalOpen(true); setRenewalFlow(true) } }
    ]

    const stepsToShow = merchant?.product === ProductType.BCA ? steps : flexiSteps

    const getApplicationStage = (application: Application) => {
        const relatedDeal = deals?.filter((x: Deal) => x.application_id === application.data.application_id)[0];
        if (relatedDeal) {
            if (relatedDeal?.status === 'Payment Setup Review') {
                if (application?.data?.contractStep !== 'Download') {
                    return MerchantStage.OffersAccepted
                }
                if (relatedDeal?.transactions && relatedDeal.transactions.length > 0) {
                    return 6
                }
                return MerchantStage.ContractSigned
            }
            if (relatedDeal?.status === 'Repaid') {
                return MerchantStage.DealRepaid
            }
            if (relatedDeal?.transactions && relatedDeal.transactions.length > 0) {
                return MerchantStage.PaymentsCreated
            }
            if (relatedDeal?.status === 'Funded') {
                return MerchantStage.PaymentSetup
            }
        }
        if (application?.data?.selectedOffer) {
            return MerchantStage.OffersAccepted
        }
        if (application?.data?.offers?.length > 0) {
            return MerchantStage.OffersCreated
        }
        return MerchantStage.Created;
    }
    const { colorMode } = useColorMode()
    const navigate = useNavigate();

    return <Container paddingTop='20px' maxW={'8xl'}>
        <Box h='100%' padding={'30px'}>
            <Stack spacing={'10px'} marginBottom={'50px'}>
                <Stack direction={'row'} pos={'relative'}>
                    <Heading fontFamily={'LiberisDisplay'} as='h4' size='lg'>Merchant Testing</Heading>
                    <Box pos={'absolute'} right={'0'}>
                        <Popover>
                            <PopoverTrigger>
                                <Button isDisabled={!initialised} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'}>LAUNCH AN EXAMPLE JOURNEY</Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent width={'250px'}>
                                    <PopoverArrow />
                                    <PopoverHeader>Core</PopoverHeader>
                                    <PopoverBody>

                                        <Button w='225px' isDisabled={!initialised} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => window.open(
                                            `${redirectUrl}?external_id=${merchantId}`,
                                            '_blank' // <- This is what makes it open in a new window.
                                        )}><Text>REDIRECT TO CORE JOURNEY</Text></Button>
                                    </PopoverBody>

                                    <PopoverHeader>Create</PopoverHeader>
                                    <PopoverBody>
                                        <Button w='225px' marginBottom={'5px'} isDisabled={!initialised} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => navigate(`/journey?merchantId=${merchantId}&currency=${merchant?.currency}`)}><Text>START EXAMPLE JOURNEY</Text></Button>
                                        <Button w='225px' isDisabled={!initialised} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='#E7FF7C' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => { navigate(`/journey?merchantId=${merchantId}&currency=${merchant?.currency}`); localStorage.clear() }}><Text>CONFIGURE EXAMPLE JOURNEY</Text></Button>
                                    </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                        <Button isDisabled={!initialised} marginLeft={'10px'} fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'0px'} bg='none' padding={2} fontSize={'14px'} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => { resetMerchant() }}><Text>RESET MERCHANT DATA</Text></Button>

                    </Box>
                </Stack>
                <Text>Review a merchants current state and test their application flow.</Text>
            </Stack>
            <Stack spacing={10}>

                <CardContainer title={`Merchant: ${merchant?.merchantId ?? ''}`}
                    subheading={
                        initialised && <Box>
                            <Text>Created: {merchant?.created?.toString().split('T')[0]}</Text>
                            <Text>{`Liberis id: ${selectedApplication?.data.liberis_id ?? ''}`}
                                {selectedApplication?.data.liberis_id && <CopyButton text={selectedApplication?.data.liberis_id} />}
                            </Text>
                        </Box>}
                    children={<Box h='100%'>

                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'6px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'6px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Merchant Id</Th>
                                            <Th >Minimum offer</Th>
                                            <Th>Maximum offer</Th>
                                            <Th>Decision</Th>
                                            <Th>Webhook configured</Th>
                                            <Th>Created</Th>
                                            <Th>Delete</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{merchant?.merchantId}</Td>
                                            <Td>{formatCurrency(merchant?.currency, merchant?.limits.minimum)}</Td>
                                            <Td>{formatCurrency(merchant?.currency, merchant?.limits.maximum)}</Td>
                                            <Td>{merchant?.decision}</Td>
                                            <Td>{merchant?.webhookUrl ? <Tag size={'sm'} variant='subtle'>
                                                <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                <TagLabel>Configured</TagLabel>
                                            </Tag> :
                                                <Tag size={'sm'} variant='subtle'>
                                                    <TagLeftIcon boxSize='12px' as={CloseIcon} />
                                                    <TagLabel>Not configured</TagLabel>
                                                </Tag>}</Td>
                                            <Td>{merchant?.created?.toString().split('T')[0]}</Td>
                                            <Td><Image src={cross} color='crimson' cursor={'pointer'} onClick={(e) => { setDeleteMerchantModal(true) }} /></Td>
                                        </Tr>

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box>} />

                <CardContainer title="Merchant progress" subheading="Click on the steps below to view progress" children={<Box>
                    <Skeleton isLoaded={initialised} borderBottomRadius={'6px'}>
                        <Box minH={'40px'}>
                            <Box float={'right'} marginTop={'-50px'} >
                                {!resetsEnabled &&
                                    <Button fontWeight={'light'} color={'green'} marginRight={'40px'} size='sm' variant={'link'} onClick={() => toggleResetEnabled(true)}>
                                        Auto reset
                                    </Button>
                                }
                                {resetsEnabled &&
                                    <Button fontWeight={'light'} color={'red'} marginRight={'40px'} size='sm' variant={'link'} onClick={() => toggleResetEnabled(false)}>
                                        Cancel reset
                                    </Button>
                                }
                            </Box>
                            {applications?.length === 0 &&
                                <Box>
                                    <Card boxShadow={'none'} borderRadius={'20px'}>
                                        <Stepper colorScheme="green" size='md' padding={'20px'} index={1}>

                                            {stepsToShow.map((step, index) => (
                                                <>
                                                    <Step onClick={() => { step.onClick() }} key={step.title} style={{ cursor: 'pointer' }} >
                                                        <Step onClick={renewalSteps[0].onClick} style={{ cursor: 'pointer' }}>
                                                            <StepIndicator backgroundColor={'#B3B3AA !important'}>
                                                                <StepStatus complete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                    <AbsoluteCenter>
                                                                        <Box bg='#E7FF7C' borderRadius={'50px'} w='20px' h='20px'>
                                                                        </Box>
                                                                    </AbsoluteCenter>
                                                                </Box>} incomplete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                    <AbsoluteCenter>
                                                                        <Box bg='white' borderRadius={'50px'} w='20px' h='20px'>
                                                                        </Box>
                                                                    </AbsoluteCenter>
                                                                </Box>} active={step.block && merchant?.decision === 'Declined' ? <CloseIcon /> :
                                                                    <Box position={'relative'} borderRadius={'50px'} w='24px' h='24px'>
                                                                        <AbsoluteCenter>
                                                                            <Box className="active" borderRadius={'50px'} w='20px' h='20px'>
                                                                            </Box>
                                                                        </AbsoluteCenter>
                                                                    </Box>} />
                                                            </StepIndicator>

                                                            <Box marginLeft={'10px'} w='5.2rem' flexWrap={'wrap'} flexShrink='5' >
                                                                <StepTitle><Text fontFamily={'FoundryPlek'} fontSize={'14px'}>{step.title.toUpperCase()}</Text></StepTitle>
                                                                <StepDescription>{step.description}</StepDescription>
                                                            </Box>
                                                        </Step>
                                                    </Step>
                                                    {(resetsEnabled) && <Box w='10px' h='50px' marginLeft={'-15px'} key={step.stage} className={step.stage.toString() === resetPoint?.toString() ? 'reset' : 'reset-empty'} onClick={() => { setResetPoint(step.stage); updateResetStage(step.stage) }}></Box >}
                                                </>
                                            ))}
                                        </Stepper>
                                    </Card>
                                </Box>
                            }
                            {applications?.map((x, index) => {
                                return <Box key={x.data?.application_id}>
                                    <Card boxShadow={'none'} border={'none'} borderRadius={'20px'}>
                                        <Stepper colorScheme="green" size='md' padding={'20px'} index={getApplicationStage(x)}>
                                            {stepsToShow.map((step, index) => (
                                                <>
                                                    <Step onClick={() => { step.onClick(); setSelectedApplication(x); setSelectedDeal(deals?.find(y => y.application_id === x.data.application_id)) }} key={step.title} style={{ cursor: 'pointer' }}>
                                                        <StepIndicator backgroundColor={'#B3B3AA !important'}>
                                                            <StepStatus complete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                <AbsoluteCenter>
                                                                    <Box bg='#E7FF7C' borderRadius={'50px'} w='20px' h='20px'>
                                                                    </Box>
                                                                </AbsoluteCenter>
                                                            </Box>} incomplete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                                <AbsoluteCenter>
                                                                    <Box bg='white' borderRadius={'50px'} w='20px' h='20px'>
                                                                    </Box>
                                                                </AbsoluteCenter>
                                                            </Box>} active={step.block && merchant?.decision === 'Declined' ? <CloseIcon /> :
                                                                <Box position={'relative'} borderRadius={'50px'} w='24px' h='24px'>
                                                                    <AbsoluteCenter>
                                                                        <Box className="active" borderRadius={'50px'} w='20px' h='20px'>
                                                                        </Box>
                                                                    </AbsoluteCenter>
                                                                </Box>} />
                                                        </StepIndicator>

                                                        <Box marginLeft={'10px'} w='5.2rem' flexWrap={'wrap'} flexShrink='5' >
                                                            <StepTitle><Text fontFamily={'FoundryPlek'} className={index === getApplicationStage(x) ? 'activeText' : ''} fontSize={'14px'}>{step.title.toUpperCase()}</Text></StepTitle>
                                                            <StepDescription>{step.description}</StepDescription>
                                                        </Box>

                                                    </Step>
                                                    {(resetsEnabled) && <Box w='10px' h='50px' marginLeft={'-15px'} className={step.stage.toString() === resetPoint?.toString() ? 'reset' : 'reset-empty'} onClick={() => { setResetPoint(step.stage); updateResetStage(step.stage) }}></Box >}
                                                </>
                                            ))}
                                        </Stepper>
                                        {
                                            index !== applications?.length - 1 &&
                                            <Card boxShadow={'none'} border={'none'} background={colorMode === 'light' ? 'rgb(250 250 250 / 70%)' : 'rgb(60 60 60 / 70%)'} position={'absolute'} width={'100%'} height={'100%'} zIndex={50}></Card>
                                        }
                                    </Card>

                                    {
                                        index !== applications?.length - 1 && <Box>
                                            <Stack direction='row' h='70px' p={6}>
                                                <Divider color={'black'} orientation='vertical' />
                                                <Text w='400px'>Renewed {x.data.createdAt}</Text>
                                                <Divider paddingTop={'10px'}></Divider>
                                            </Stack>
                                        </Box>
                                    }

                                </Box>
                            })}


                            {
                                latestDeal?.performance.renewal_eligibility && applications?.length === deals?.length &&
                                <Box >
                                    <Stack direction='row' h='70px' p={6} w='100%'>
                                        <Divider color={'black'} orientation='vertical' />
                                        <Text w='200px'>Deal can be renewed </Text><Image marginTop={'-5px'} h='30px' w='30px' src='https://em-content.zobj.net/source/telegram/358/money-bag_1f4b0.webp'></Image>
                                        <Divider paddingTop={'10px'}></Divider>
                                    </Stack>
                                    <Card width={'370px'} boxShadow={'none'} border={'none'} borderRadius={'20px'}>
                                        <Stepper colorScheme="green" size='md' padding={'20px'} index={1}>

                                            <Step onClick={renewalSteps[0].onClick} style={{ cursor: 'pointer' }}>
                                                <StepIndicator backgroundColor={'#E7FF7C !important'}>
                                                    <StepStatus complete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                        <AbsoluteCenter>
                                                            <Box bg='#15B79E' borderRadius={'50px'} w='20px' h='20px'>
                                                            </Box>
                                                        </AbsoluteCenter>
                                                    </Box>} incomplete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                        <AbsoluteCenter>
                                                            <Box bg='white' borderRadius={'50px'} w='20px' h='20px'>
                                                            </Box>
                                                        </AbsoluteCenter>
                                                    </Box>} active={
                                                        <Box position={'relative'} borderRadius={'50px'} w='24px' h='24px'>
                                                            <AbsoluteCenter>
                                                                <Box className="active" borderRadius={'50px'} w='20px' h='20px'>
                                                                </Box>
                                                            </AbsoluteCenter>
                                                        </Box>} />
                                                </StepIndicator>

                                                <Box marginLeft={'10px'} w='5.2rem' flexWrap={'wrap'} flexShrink='5' >
                                                    <StepTitle><Text fontSize={'14px'}>{renewalSteps[0].title}</Text></StepTitle>
                                                    <StepDescription>{renewalSteps[0].description}</StepDescription>
                                                </Box>
                                            </Step>

                                            <Step onClick={renewalSteps[1].onClick} style={{ cursor: 'pointer' }}>
                                                <StepIndicator backgroundColor={'#E7FF7C !important'}>
                                                    <StepStatus complete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                        <AbsoluteCenter>
                                                            <Box bg='#15B79E' borderRadius={'50px'} w='20px' h='20px'>
                                                            </Box>
                                                        </AbsoluteCenter>
                                                    </Box>} incomplete={<Box position={'relative'} bg='white' borderRadius={'50px'} w='24px' h='24px'>
                                                        <AbsoluteCenter>
                                                            <Box bg='white' borderRadius={'50px'} w='20px' h='20px'>
                                                            </Box>
                                                        </AbsoluteCenter>
                                                    </Box>} active={
                                                        <Box position={'relative'} borderRadius={'50px'} w='24px' h='24px'>
                                                            <AbsoluteCenter>
                                                                <Box className="active" borderRadius={'50px'} w='20px' h='20px'>
                                                                </Box>
                                                            </AbsoluteCenter>
                                                        </Box>} />
                                                </StepIndicator>

                                                <Box marginLeft={'10px'} w='5.2rem' flexWrap={'wrap'} flexShrink='5' >
                                                    <StepTitle><Text fontSize={'14px'} className="activeText">{renewalSteps[1].title}</Text></StepTitle>
                                                    <StepDescription>{renewalSteps[1].description}</StepDescription>
                                                </Box>
                                                <StepSeparator />
                                            </Step>
                                        </Stepper>
                                    </Card>
                                </Box>
                            }
                        </Box>
                    </Skeleton>
                </Box >} />


                < CardContainer title={`Client and revenue claims`}
                    subheading={< Text >Any claims and revenue submitted via the <Link href='https://liberis-api-v2.readme.io/reference/create-partner-client' isExternal>Claims API<ExternalLinkIcon mx='2px' />
                    </Link> will be displayed here. Revenue submitted will affect the maximum offer amount for this test merchant.</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'6px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'6px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Company id</Th>
                                            <Th>Company start date</Th>
                                            <Th>Entity type</Th>
                                            <Th>Revenue claims</Th>
                                            <Th>Total revenue</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {revenue && <Tr cursor={'pointer'} key={revenue?.partner_client_id} onClick={() => { setSelectedChannel(revenue?.partner_client_id); setViewPrequalModal(true) }}>
                                            <Td>{revenue?.partner_client_id}</Td>
                                            <Td>{revenue?.client_start_date?.toString()}</Td>
                                            <Td>{revenue?.entity_type}</Td>
                                            <Td>{revenue?.revenue?.length ? revenue?.revenue?.length : ''}</Td>
                                            <Td>{revenue?.revenue?.reduce(
                                                (accumulator, currentValue) => accumulator + Number(currentValue.total_amount),
                                                0,
                                            )}</Td>
                                        </Tr>}

                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </Skeleton>
                    </Box >} />


                < CardContainer title={`Applications`}
                    subheading={< Text > {`Current application id: ${selectedApplication?.data.application_id ?? 'Created upon offer generation'}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'6px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'6px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Merchant Id</Th>
                                            <Th>Legal name</Th>
                                            <Th>Status</Th>
                                            <Th>Offer Amount</Th>
                                            <Th>Deal created</Th>
                                            <Th>Type</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {applications?.map((x: any) => {
                                            return <Tr key={x.data?.application_id}>
                                                <Td>{x?.merchantId}</Td>
                                                <Td>{x?.data?.company?.legal_name}</Td>
                                                <Td>{x?.data?.status}</Td>
                                                <Td>{formatCurrency(x?.data?.selectedOffer?.currency, x?.data?.selectedOffer?.funded_amount)}</Td>
                                                <Td>{x?.data?.dealId ? <Tag size={'sm'} variant='subtle'>
                                                    <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                    <TagLabel>Created</TagLabel>
                                                </Tag> :
                                                    <Tag size={'sm'} variant='subtle' colorScheme='red'>
                                                        <TagLeftIcon boxSize='12px' as={CloseIcon} />
                                                        <TagLabel>No deal</TagLabel>
                                                    </Tag>}</Td>
                                                <Td>{x?.data?.applicationType}</Td>
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                            Actions
                                                        </MenuButton>
                                                        <MenuList>
                                                            <MenuItem onClick={() => { setSelectedApplication(x); setApplicationStatusModal(true) }}>Change status</MenuItem>
                                                            <MenuItem onClick={() => { setSelectedApplication(x); setApplicationDataModal(true) }}>View data</MenuItem>
                                                            <MenuItem onClick={() => { deleteApplication(x?.merchantId) }}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        })}

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />


                < CardContainer title={`Deals`}
                    subheading={< Text > {`Current deal id: ${selectedDeal?.id ?? 'Created upon offer generation'}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'6px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'6px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Merchant Id</Th>
                                            <Th>Status</Th>
                                            <Th>Balance</Th>
                                            <Th>Payments</Th>
                                            <Th>Paid off</Th>
                                            <Th>Type</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {deals?.map((deal: Deal) => {
                                            return (
                                                <Tr key={deal.id}>
                                                    <Td>{deal?.merchantId}</Td>
                                                    <Td>{deal?.status}</Td>
                                                    <Td>{deal?.currency === 'GBP' ? '£' : '$'}{deal?.balance}</Td>
                                                    <Td>{deal?.transactions?.length}</Td>
                                                    <Td>{deal?.currency === 'GBP' ? '£' : '$'}{deal?.pricing.repayment_amount - deal.balance}</Td>
                                                    <Td>{deal?.type}</Td>
                                                    <Td>
                                                        <Menu>
                                                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                                Actions
                                                            </MenuButton>
                                                            <MenuList>
                                                                <MenuItem onClick={() => { setSelectedDeal(deal); setViewPaymentModal(true) }}>View payments</MenuItem>
                                                                {deal?.status !== 'Funded' && <MenuItem onClick={() => { setSelectedDeal(deal); setPaymentSetupModal(true) }} >Setup payment mechanism</MenuItem>}
                                                                {deal?.status === 'Funded' && <MenuItem onClick={() => { setSelectedDeal(deal); setPaymentModal(true) }} >Add payment</MenuItem>}
                                                                <MenuItem onClick={() => { setSelectedDeal(deal); setDealStatusModal(true) }} >Change status</MenuItem>
                                                                <MenuItem onClick={() => { setSelectedDeal(deal); setDealDataModal(true) }} >View data</MenuItem>
                                                                <MenuItem onClick={() => { deleteDeal(deal?.id) }}>Delete</MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </Td>
                                                </Tr>)
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />


                < CardContainer title={`Webhooks`}
                    subheading={< Text > {`${merchant?.webhookUrl ? `Webhook url: ${merchant?.webhookUrl}` : 'No webhook configuration set for merchant'}`}</Text >} children={< Box h='100%' >
                        <Heading as='h4' size='md'></Heading>
                        <Skeleton isLoaded={initialised} borderBottomRadius={'6px'}>
                            <TableContainer marginTop={'0px'} borderBottomRadius={'6px'}>
                                <Table variant='simple' size={'md'}>
                                    <Thead bg={useColorModeValue('#F8F9FA', '#3e485a')}>
                                        <Tr>
                                            <Th>Created</Th>
                                            <Th>Merchant</Th>
                                            <Th>event</Th>
                                            <Th>Success</Th>
                                            <Th>Response code</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {webhooks?.map(x => {
                                            return <Tr key={x.id} cursor={'pointer'} onClick={() => { setSelectedWebhook(webhooks.filter((y: Webhook) => y.id === x.id)[0]); setWebhookModal(true) }}>
                                                <Td>{x.created.toString()}</Td>
                                                <Td>{x.merchantId}</Td>
                                                <Td>{x.event}</Td>
                                                <Td>{x.success ? <Tag size={'sm'} variant='subtle'>
                                                    <TagLeftIcon boxSize='12px' as={CheckIcon} />
                                                    <TagLabel>OK</TagLabel>
                                                </Tag> :
                                                    <Tag size={'sm'} variant='subtle' colorScheme='red'>
                                                        <TagLeftIcon boxSize='12px' as={CloseIcon} />
                                                        <TagLabel>FAILED</TagLabel>
                                                    </Tag>}</Td>
                                                <Td>{x.code}</Td>
                                            </Tr>
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Skeleton>
                    </Box >} />

                < ApplicationDataWebhook dataModalOpen={applicationDataModal} setDataModal={setApplicationDataModal} selectedApplication={selectedApplication} />
                <ApplicationStatusWebhook statusModalOpen={applicationStatusModalOpen} setStatusModal={setApplicationStatusModal} selectedApplication={selectedApplication} connectionLost={props.connectionLost} />
                <ViewDealModal dataModalOpen={dataModalOpen} setDataModal={setDataModal} deal={selectedDeal} />
                <PaymentSetupModal setPaymentSetupModal={setPaymentSetupModal} paymentSetupModalOpen={paymentSetupModalOpen} deal={selectedDeal} />
                <CreatePaymentModal setPaymentModal={setPaymentModal} paymentModalOpen={paymentModalOpen} deal={selectedDeal} userId={props.userId} connectionLost={props.connectionLost} />
                <DealStatusModal statusModalOpen={dealStatusModalOpen} setStatusModal={setDealStatusModal} deal={selectedDeal} connectionLost={props.connectionLost} />
                <ViewPaymentsModal viewPaymentModalOpen={viewPaymentModalOpen} setViewPaymentModal={setViewPaymentModal} data={selectedDeal} />
                <ViewPrequalifiedDataModal viewPrequalModalOpen={viewPrequalModalOpen} setViewPrequalModal={setViewPrequalModal} revenue={revenue} selectedChannel={selectedChannel} />
                <AcceptOfferModal acceptModalOpen={acceptModalOpen} setAcceptModal={setAcceptModal} application={selectedApplication} merchant={merchant} />
                <GetOffersModal getOffersModalOpen={getOffersModalOpen} setGetOffersModalOpen={setGetOffersModalOpen} merchant={merchant} isRenewal={renewalFlow} liberisId={selectedDeal?.liberis_id} />
                <DealRepaidModal dealRepaidModalOpen={dealRepaidModalOpen} setDealRepaidModal={setDealRepaidModal} deal={selectedDeal} />
                <ViewWebhook webhookModal={webhookModal} setWebhookModal={setWebhookModal} selectedWebhook={selectedWebhook} />
                <TestWebhookModal canRenew={deals && deals.length > 1} testWebhookModal={testWebhookModal} setTestWebhookModal={setTestWebhookModal} userId={props.userId} selectedMerchant={merchant} connectionLost={props.connectionLost} />
                <ViewDealModal dataModalOpen={dealDataModalOpen} setDataModal={setDealDataModal} deal={selectedDeal} />
                <ContractModal contractModal={contractModal} setContractModal={setContractModal} applicationId={selectedApplication?.data?.application_id} currentContractStep={selectedApplication?.data?.contractStep} />
                <DeleteMerchantModal deleteModalOpen={deleteMerchantModal} setDeleteModal={setDeleteMerchantModal} merchantId={merchant?.merchantId} />
            </Stack >
        </Box >

    </Container >
}

export default MerchantComponent