import React from 'react';
import ReactDOM from 'react-dom/client';
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'
import { cardAnatomy } from '@chakra-ui/anatomy'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider, ColorModeScript, Container } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ContractPage from './components/ContractPage';

import './index.css';
import { ConfigProvider } from './providers/ConfigProvider';


const themeconf: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'blackAlpha.700', //change the background
  },
  dialog: {
    borderRadius: '2xl',
  },
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
})

// const cardStyle = definePartsStyle({
//   // define the part you're going to style
//   container: {
//     borderRadius: '20px',
//     boxShadow: '0px 5px 22px 0px #0000000A'
//   }
// })




// 3. extend the theme
const theme = extendTheme({
  config: themeconf, components: { Modal: modalTheme, Stepper: { step: { background: 'black' } } },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(

  <Auth0Provider
    domain="prod-liberis-external.eu.auth0.com"
    clientId="EaKGZG8duczzwRLyCaer3DuoRjpVUURR"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <ColorModeScript initialColorMode={'dark'} />
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom-right' } }}>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </ChakraProvider>
  </Auth0Provider>
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
