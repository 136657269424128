import { Alert, AlertIcon, Box, Button, Card, ChakraProvider, Container, Heading, Stack, Image, useColorModeValue, Text, Center, Flex, FormControl, FormLabel, Input, Textarea, Divider, useToast, CardHeader, CardBody, Tabs, Tab, TabList, TabPanel, TabPanels } from "@chakra-ui/react";
import { JsonViewer } from "@textea/json-viewer";
import axios from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { useConfig } from "../hooks/useConfig";

export interface Advert {
    header: string,
    title: string,
    subtitle: string,
    body: string,
    footer: string,
    cta: string,
    redirectUrl: string
}
const TailoredMarketingPage: FC = () => {

    const [advert, setAdvert] = useState<Advert>({
        header: "You're eligible to apply for ",
        title: "##offer## for your business",
        subtitle: "Plus, receive an additional $250 when funded!",
        body: "Paid into your business bank account once successfully funded by Liberis. Terms and Conditions apply.",
        footer: 'Terms and conditions apply',
        cta: "Get A Quote",
        redirectUrl: "https://google.com"
    })

    const { userId } = useContext(AppContext);
    const config = useConfig()

    const toast = useToast();

    useEffect(() => {
        const getAdvert = async () => {
            try {
                const res = await axios.get(`${config?.api.base}${config?.api.control.marketing}?partner=${userId}`)
                if (res.data.tailoredAdvert) {
                    setAdvert(res.data.tailoredAdvert)
                }
            }
            catch (e) {
                console.log(e);
            }
        }

        if (userId) {
            getAdvert();
        }
    }, [userId])



    const saveAdvert = async () => {
        try {
            await axios.post(`${config?.api.base}${config?.api.control.marketing}`, {
                partner: userId,
                tailoredAdvert: advert
            })
            toast({
                title: 'Advert copy successfully updated',
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
        }
        catch (e) {
            toast({
                title: 'Advert update failed',
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
        }
    }

    return <ChakraProvider>
        <Container paddingTop='20px' maxW={'8xl'}>
            <Box h='100%' padding={'30px'}>
                <Heading fontFamily={'LiberisDisplay'} size={'lg'}>Tailored adverts</Heading>
                <br></br>
                <Text> When Liberis find an offer for a merchant, we will return a tailored advert containing merchant offer specific information. This is an opportunity to return a customised and highly personalised offer to target individuals inside the defined selection process.</Text>
            </Box>
            <Box maxW={'1xl'}>
                <Center>
                    <Box
                        padding={''}
                        rounded={'sm'}
                        overflow={'hidden'}
                        border={'1px'}
                        borderColor="black"
                        bg={useColorModeValue('white', 'gray.600')}
                        boxShadow={useColorModeValue('6px 6px 0 black', '6px 6px 0 gray')}
                        maxW={'80%'}>
                        <Adverts advert={advert} />
                    </Box>
                </Center>
                <Center>
                    <Stack textAlign={'center'}>
                        <Heading fontFamily={'LiberisDisplay'} marginTop={'20px'} size={'sm'}>Example banner advert</Heading>
                        <Heading fontFamily={'LiberisDisplay'} size={'xs'}>(£20k offer)</Heading>
                    </Stack>
                </Center>

            </Box>
            <Divider/>
            <Tabs variant='soft-rounded' colorScheme='green'>
                <Heading fontFamily={'FoundryPlek'} size={'md'} m='0 10% 20px 11%'>Configuration</Heading>

                <TabList m='0 0% 0 12%'>
                    <Tab>Advert copy</Tab>
                    <Tab>Redirect url</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Card m='0 10% 0 10%'>
                            <EditAdvert tailored={true} saveAdvert={saveAdvert} advert={advert} setAdvert={setAdvert} />
                        </Card>
                    </TabPanel>
                    <TabPanel>
                        <Card m='0 10% 0 10%'>
                            <EditRedirect saveAdvert={saveAdvert} advert={advert} setAdvert={setAdvert} />
                        </Card>
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Container >
    </ChakraProvider >
}

export const Adverts = (props: {
    advert: any
}) => {

    const advert = JSON.parse(JSON.stringify(props.advert).replaceAll('##offer##', '£20,000'));
    return (
        <ChakraProvider>
            <Container maxW={'1xl'}>
                <Card border='none' boxShadow={'none'}>

                    <Stack
                        p={'150px'}
                        as={Box}
                        textAlign={'center'}
                        py={{ base: 5, md: 8 }}>
                        <Heading
                            fontFamily={'LiberisDisplay'}
                            fontWeight={600}
                            fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}
                            color={'#13D58F'}
                            lineHeight={'110%'}>
                            {advert.header}<br></br>
                            <Text as={'span'} color={'#13D58F'}>
                                {advert.title}
                            </Text>
                        </Heading>
                        <Text>
                            {advert.subtitle}
                        </Text>
                        <Text>
                            {advert.body}
                        </Text>
                        <Stack
                            direction={'column'}
                            spacing={3}
                            align={'center'}
                            alignSelf={'center'}
                            position={'relative'}>
                            <Button
                                onClick={() => window.open(props.advert.redirectUrl, '_blank')}
                                bg={'#13D58F'}
                                rounded={'full'}
                                px={6}
                                _hover={{
                                    bg: '#13D58F',
                                }}>
                                {advert.cta}
                            </Button>
                            <Text fontSize={'xs'}>
                                {advert.footer}
                            </Text>
                        </Stack>
                    </Stack>
                </Card>
            </Container>
        </ChakraProvider>
    )
}


export const EditAdvert = (props: {
    advert: Advert
    saveAdvert: any,
    setAdvert: any,
    tailored: boolean,
    disabled?: boolean
}) => {

    return (

        <Card>
            <CardHeader borderBottom={'1px solid #E2E8F0'}>
                <Stack direction={{ base: 'column', md: 'row' }}>
                    <Image w='25px' src='https://em-content.zobj.net/source/google/350/pencil_270f-fe0f.png' />
                    <Heading fontFamily={'FoundryPlek'} size={'md'}>EDIT {props.tailored ? 'TAILORED' : 'DEFAULT'} ADVERT</Heading>
                </Stack>
            </CardHeader >
            <CardBody>
                <Stack direction={{ base: 'column', md: 'row' }}>
                    <Flex p={3} flex={1} >
                        <Stack spacing={2} w={'full'} maxW={'xl'}>
                            <FormControl id="header">
                                <FormLabel>Header</FormLabel>
                                <Input disabled={props.disabled}
                                    value={props.advert.header}
                                    onChange={(e) => props.setAdvert({ ...props.advert, header: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="Title">
                                <FormLabel>Title</FormLabel>
                                <Input disabled={props.disabled}
                                    value={props.advert.title}
                                    onChange={(e) => props.setAdvert({ ...props.advert, title: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="Subtitle">
                                <FormLabel>Subtitle</FormLabel>
                                <Input disabled={props.disabled}
                                    value={props.advert.subtitle}
                                    onChange={(e) => props.setAdvert({ ...props.advert, subtitle: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="Body">
                                <FormLabel>Body</FormLabel>
                                <Textarea disabled={props.disabled}
                                    value={props.advert.body}
                                    onChange={(e) => props.setAdvert({ ...props.advert, body: e.target.value })}
                                    size='sm'
                                />
                            </FormControl>
                            <FormControl id="footer">
                                <FormLabel>Footer</FormLabel>
                                <Input disabled={props.disabled}
                                    value={props.advert.footer}
                                    onChange={(e) => props.setAdvert({ ...props.advert, footer: e.target.value })}
                                />
                            </FormControl>
                            <FormControl id="cta">
                                <FormLabel>Call to action</FormLabel>
                                <Input disabled={props.disabled}
                                    value={props.advert.cta}
                                    onChange={(e) => props.setAdvert({ ...props.advert, cta: e.target.value })}
                                />
                            </FormControl>
                            {
                                !props.disabled &&
                                <Box textAlign={'left'}>
                                    <Button fontFamily={'FoundryPlek'} color={'#232C2F'} marginTop={'40px'} bg='#E7FF7C' padding={2} borderRadius={'25px'} border={'1px solid #232C2F'} onClick={() => props.saveAdvert()}>
                                        SAVE ADVERT
                                    </Button>
                                </Box>
                            }


                        </Stack>

                    </Flex>
                    <Flex padding={'20px'} bg='gray.100' borderRadius={'10px'} flex={1}>

                        <Stack>
                            <Heading color='gray.600' size='xs'>GET /create/v2/advert response (£20k offer)</Heading>
                            <Divider></Divider>
                            <JsonViewer value={JSON.parse(JSON.stringify({
                                "meta": {
                                    "reference": "reference"
                                },
                                "advert_data": {
                                    "header": props.advert.header,
                                    "title": props.advert.title,
                                    "locale": "en-gb",
                                    "offer_status": "Eligible",
                                    "call_to_action": props.advert.cta,
                                    "header_style": null,
                                    "title_style": null,
                                    "subtitle": props.advert.subtitle,
                                    "subtitle_style": null,
                                    "body": props.advert.body,
                                    "body_style": null,
                                    "call_to_action_style": null,
                                    "footer": props.advert.footer,
                                    "footer_style": null,
                                    "background_image_url": null,
                                    "html": `<div style=\"height: 300px; width: 240px; background-image: url()\"/><div style=\"\">${props.advert.title}</div><div style=\"\">${props.advert.subtitle}</div><div style=\"\">${props.advert.header}</div><div style=\"\">${props.advert.body}.</div><div style=\"\">${props.advert.cta}</div>`,
                                }
                            }).replaceAll('##offer##', '£20,000'))} />
                        </Stack>

                    </Flex>
                </Stack >
            </CardBody>
        </Card >

    )
}



export const EditRedirect = (props: {
    advert: Advert
    saveAdvert: any,
    setAdvert: any,
    disabled?: boolean
}) => {

    return (

        <Card>
            <CardHeader borderBottom={'1px solid #E2E8F0'}>
                <Stack direction={{ base: 'column', md: 'row' }}>
                    <Image w='25px' src='https://em-content.zobj.net/source/google/350/pencil_270f-fe0f.png' />
                    <Heading size={'md'}>Edit redirect url</Heading>
                </Stack>
            </CardHeader >
            <CardBody>
                <Stack direction={{ base: 'column', md: 'row' }}>
                    <Flex p={3} flex={1} >
                        <Stack spacing={2} w={'full'} maxW={'xl'}>

                            <FormControl id="header">
                                <FormLabel>Redirect url</FormLabel>
                                <Input disabled={props.disabled}
                                    value={props.advert.redirectUrl}
                                    onChange={(e) => props.setAdvert({ ...props.advert, redirectUrl: e.target.value })}
                                />
                            </FormControl>
                            {!props.disabled &&
                                <Box textAlign={'right'}>
                                    <Button float={'right'} marginTop={'40px'} w='150px' bg='green.300' onClick={() => props.saveAdvert()} >
                                        Save advert
                                    </Button>
                                </Box>
                            }
                        </Stack>

                    </Flex>
                    <Flex padding={'20px'} bg='gray.100' borderRadius={'10px'} flex={1}>

                        <Stack>
                            <Heading color='gray.600' size='xs'>GET /create/v2/advert/accept response (£20k offer)</Heading>
                            <Divider></Divider>
                            <JsonViewer value={
                                { redirectUrl: props.advert.redirectUrl }
                            } />
                        </Stack>

                    </Flex>
                </Stack >
            </CardBody>
        </Card >

    )
}



export default TailoredMarketingPage